import React, { useState, useEffect } from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date('2024-12-31') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);


   // Dynamically update the page title
   useEffect(() => {
    document.title = "Prakhar Bhardwaj | Portfolio - Coming Soon";
  }, []);

  const timerComponents = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval]) {
      return null;
    }

    return (
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <div className="coming-soon-container">
      <div className="overlay"></div>
      <div className="content">
        <h1>Coming Soon</h1>
        <p>I am working hard to launch my portfolio website. Stay tuned!</p>
        <div className="countdown">{timerComponents.length ? timerComponents : <span>Time's up!</span>}</div>
        <div className="social-icons">
          <a href="https://facebook.com/yuvuu" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://instagram.com/prakharbh" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://www.linkedin.com/in/bhardwajprakhar/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
